.FlowDateAdded {
    margin-bottom: 0;

    &.task-flow {
        margin: 6px 0 0 auto;
        padding-top: 6px;
        color: #666;
        text-align: right;
        font-size: 0.75em;
    }
}
