@import "../../styles/mixins";

.VerificationButton {
    @include flex(center, center);

    flex: 1;

    &.btn {
        line-height: normal;
    }
}
