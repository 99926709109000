.btn {
    &.btn-block {
        &.flow-selector-button {
            margin-top: 15px;
            margin-right: auto;
            margin-left: auto;

            &--choose {
                width: 70%;
            }

            &:active,
            &:focus {
                background: var(--main-color);
                color: #fff;
            }

            &:hover:not(:disabled) {
                border-color: var(--theme-color);
                background-color: var(--theme-color);
            }
        }
    }
}
