@import "../../styles/mixins";

.ScreenshotLexemes {
    position: absolute;
    z-index: 15;
    top: 0;
    left: 0;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: 10px 0;
    padding: 0 0 0 20px;

    &.expanded-down {
        top: 100%;
        height: auto;
        padding-left: 0;
        border-top: 1px solid var(--theme-color);
        background-color: #fff;
        transition: top 0.3s ease 0s;

        h2 {
            padding-top: 6px;
        }
    }

    h2 {
        margin-bottom: 0;
        padding: 0 0 10px 0;
        border-bottom: 2px dotted var(--theme-color);
    }

    > p {
        margin-top: 10px;
        white-space: normal;
    }

    ul {
        overflow-y: auto;
        height: 100%;
        padding: 6px;
        list-style: none;

        > li {
            @include overflowX();

            position: relative;
            margin-top: 8px;
            padding-left: 12px;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
                content: "\2022";
                color: var(--theme-color);
            }
        }
    }
}
