@import "../../styles/vars";

.TranslatorMultiChoose {
    position: relative;

    > .check-icon {
        position: absolute;
        top: 0;
        right: 0;
        color: $success-green;
    }
}
