.dropdown {
    .dropdown-toggle {
        @include flex(space-between, center, $wrap: nowrap);

        overflow: hidden;

        &::after {
            transform: rotate(0deg);
            transition: transform 0.25s;
        }
    }

    &.show {
        .dropdown-toggle {
            &::after {
                transform: rotate(-180deg);
            }
        }
    }

    .dropdown-menu {
        overflow-x: hidden;
        overflow-y: auto;
    }
}
