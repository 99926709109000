@import "./vars";

@keyframes screenshot-approved {
    0% {
        border-top-color: transparent;
    }

    25% {
        border-top-color: $success-green;
    }

    50% {
        border-top-color: $success-green;
        border-right-color: $success-green;
    }

    75% {
        border-top-color: $success-green;
        border-right-color: $success-green;
        border-bottom-color: $success-green;
    }

    100% {
        border-color: $success-green;
    }
}
@keyframes screenshot-needs-retest {
    0% {
        border-top-color: transparent;
    }

    25% {
        border-top-color: $error-red;
    }

    50% {
        border-top-color: $error-red;
        border-right-color: $error-red;
    }

    75% {
        border-top-color: $error-red;
        border-right-color: $error-red;
        border-bottom-color: $error-red;
    }

    100% {
        border-color: $error-red;
    }
}
@keyframes screenshot-reassigned {
    0% {
        border-top-color: transparent;
    }

    25% {
        border-top-color: $warning-orange;
    }

    50% {
        border-top-color: $warning-orange;
        border-right-color: $warning-orange;
    }

    75% {
        border-top-color: $warning-orange;
        border-right-color: $warning-orange;
        border-bottom-color: $warning-orange;
    }

    100% {
        border-color: $warning-orange;
    }
}
