@import "../../styles/mixins";
@import "../../styles/vars";

.FlowPage {
    position: relative;

    .Button {
        z-index: 1;
        width: 100%;
        margin-bottom: 20px;
        background: #01aac4;
        background: var(--main-color);
        color: #fff;
        font-weight: bold;

        &:active,
        &:focus {
            background: var(--main-color);
            color: #fff;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .SuggestionButton {
        width: 100%;
        margin-bottom: 10px;
        color: #fff;
        font-weight: bold;

        &:active,
        &:focus {
            background: var(--main-color);
            color: #fff;
        }
    }

    .FlowPageFlowDescription {
        margin-bottom: 30px;

        .card-body {
            display: grid;
            grid-template-columns: repeat(2, auto);
            column-gap: 24px;

            .flow-dates {
                justify-self: end;
            }
        }

        .FlowComment {
            @include flex(start, start, $wrap: nowrap);

            grid-column: span 2;
            justify-self: start;
            height: auto;
            margin-top: 10px;

            > .add-comment-button {
                position: relative;
                z-index: 20;
                width: calc(36px + 0.375rem);
                height: calc(36px + 0.375rem);
                margin-right: 10px;
                padding: 0.175rem 0.35rem;

                &.btn-warning {
                    color: #fff;
                }
            }

            .flow-comment-field {
                @include flex(start, end, $wrap: nowrap);

                position: relative;
                z-index: 5;
                left: 0;
                flex: 1;
                max-height: 100%;
                opacity: 1;
                visibility: visible;
                transition: left 0.2s ease-in-out, opacity 0.2s ease-in-out,
                    max-height 0.25s ease-in 0.2s;

                &.hidden {
                    left: -20px;
                    max-height: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: left 0.2s ease-in-out, opacity 0.2s ease-in-out,
                        visibility 0s ease-in-out 0.2s, max-height 0s ease-in 0.2s;
                }

                .flow-comment-textarea {
                    margin-right: 6px;

                    &.required {
                        border-color: $error-red;
                    }

                    &.form-control {
                        resize: none;
                    }
                }

                .flow-comment-static-text {
                    width: 100%;
                    min-height: calc(36px + 0.375rem);
                    margin-right: 6px;
                    padding: 0.375rem 0.75rem;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    background-color: #f5f5f5;
                    white-space: pre-wrap;
                    font-size: 1rem;
                    line-height: 1.5;
                }
            }

            .flow-comment-buttons {
                @include flex(start, $wrap: nowrap);

                margin: 0;

                > button {
                    width: calc(36px + 0.375rem);
                    height: calc(36px + 0.375rem);
                    margin-top: auto;
                    font-size: 14px;
                }

                .delete-comment-button {
                    @include flex(center, center);

                    position: absolute;
                    top: calc(-10px - 0.375em);
                    right: calc(-10px + 0.375em);
                    width: calc(20px + 0.375rem);
                    height: calc(20px + 0.375rem);
                    font-size: 9px;
                }
            }
        }

        .task-parameters {
            @include flex(center);

            font-size: 14px;
        }

        .flow-location {
            @include flex(center);
            font-size: 14px;
            pre {
                margin-bottom: 0;
            }
        }
    }

    .FlowVerificationComment {
        margin-bottom: 20px;

        h5 {
            margin-bottom: 0.4em;
            font-weight: 600;
            font-size: 16px;
        }
    }
}
