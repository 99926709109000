@import "../../styles/mixins";

.ScreenshotComparisonOverlay {
    position: fixed;
    z-index: 10;
    // top: 52px; // header height
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: auto;
    padding: 1% 5%;
    background: rgb(255 255 255 / 95%);
}

.ScreenshotComparison {
    outline: none;

    h1,
    h2,
    h4 {
        display: block;
        text-align: center;
        font-weight: normal;
    }

    h4 {
        margin-bottom: 40px;
        color: #888;
    }

    .dismiss {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .Comparison {
        @include flex(center);
    }
}

.ScreenshotWithDropdown {
    .dropdown {
        margin-bottom: 20px;
    }

    .dropdown,
    .dropdown button {
        width: 100%;
        text-align: left;
    }

    .caret {
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -1px;
    }

    .screenshot-container {
        position: relative;
        z-index: 12;

        .Screenshot {
            z-index: 20;

            .img-container {
                background-color: #fff;
            }
        }
    }
}
