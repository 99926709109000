@import "../../styles/vars";
@import "../../styles/mixins";
@import "../../styles/animation";

.Screenshot {
    position: relative;
    z-index: 1;

    &:hover {
        .ScreenshotBottomOverlay,
        > p {
            display: block;
        }
    }

    .img-container {
        @include flex(center, center);

        position: relative;
        z-index: 10;
        height: 0;
        padding-bottom: 100%;
        border-radius: 5px;

        .loader-container {
            background: rgba(0, 0, 0, 0.1);
        }

        .img-failed {
            @include flex(center, center, column);

            background: rgba($error-red, 0.4);
        }

        &.screenshot-approved,
        &.screenshot-needs-retest,
        &.screenshot-reassigned {
            > svg {
                position: absolute;
                bottom: -34px;
                border-radius: 50%;
                font-size: 26px;
            }

            &::before {
                position: absolute;
                z-index: 20;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                content: "";
                border: 5px solid transparent;
            }
        }

        &.screenshot-approved {
            &::before {
                animation: screenshot-approved 0.7s ease-in-out;
                animation-fill-mode: forwards;
            }

            > svg {
                color: $success-green;
            }
        }

        &.screenshot-needs-retest {
            &::before {
                animation: screenshot-needs-retest 0.7s ease-in-out;
                animation-fill-mode: forwards;
            }

            > svg {
                color: $error-red;
            }
        }

        &.screenshot-reassigned {
            &::before {
                animation: screenshot-reassigned 0.7s ease-in-out;
                animation-fill-mode: forwards;
            }

            > svg {
                color: $warning-orange;
            }
        }

        > *:first-child {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-shadow: 0 3px 5px -1px #ddd;
        }

        img {
            cursor: pointer;
        }
    }

    > p {
        position: absolute;
        z-index: 1;
        display: none;
        width: 100%;
        padding: 2px 5px;
        color: #fff;
        text-align: center;
        font-size: 11px;

        &::after {
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            content: "";
            background: var(--main-color);
            opacity: 0.8;
        }
    }
}

.ScreenshotFooter {
    @include flex(space-between, center, $wrap: nowrap);

    overflow: hidden;
    margin-top: 14px;

    span {
        overflow: hidden;
        width: 33%;
        color: #888;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 11px;

        &.left {
            text-align: left;
        }

        &.center {
            height: $height--comparison-indicator;
            margin: 0 0.25em;
            border-radius: 5px;
            cursor: pointer;
        }

        &.right {
            text-align: right;
        }
    }
}

.ScreenshotBottomOverlay {
    position: absolute;
    z-index: 15;
    bottom: $height--screenshot-footer;
    display: none;
    width: 100%;
    padding: 52px 5px 5px;
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    text-align: right;
}

.overlay-buttons {
    position: absolute;
    right: 0;
    margin-top: 2%;
}

.ScreenshotCaption {
    @include flex(center, center);

    padding: 10px 5px 5px 5px;
    color: #5f5f5f;
    text-align: center;
    font-weight: 500;
}

$side-padding: 0 5%;

.FullScreenshotOverlay {
    position: fixed;
    z-index: 3;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $side-padding;
    background: white;
}

.FullScreenshot {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $side-padding;

    .ScreenshotContent {
        position: absolute;
        top: 10px;
        right: 10px;
        bottom: 10px;
        left: 10px;
        background-color: #fcfcfc;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: contain;
    }
}

.fullscreen-button {
    position: relative;
    top: 50%;
    width: 100px;
    height: 100px;
    transform: translateY(-50%);
}

.ScreenshotVerification {
    $margin: 12px;

    position: sticky;
    z-index: 30;
    bottom: 0;
    margin-top: $margin;
    padding: 8px;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.05);

    > textarea {
        border: 1px solid transparent;
        transition: border-color 0.4s;
    }

    &::before {
        position: absolute;
        top: 9px;
        right: 0;
        padding: 0 calc(8px + 0.75rem);
        content: "a comment is required for retest";
        color: $error-red;
        opacity: 0;
        font-size: 12px;
        transition: opacity 0.4s;
    }

    &.required {
        > textarea {
            border-color: $error-red;
        }

        &::before {
            opacity: 1;
        }
    }

    .screenshot-verification-buttons {
        @include flex(center, center);

        margin-top: $margin;

        > .btn {
            &:first-of-type {
                margin-right: 6px;
            }
        }
    }

    .card {
        margin-top: $margin;
    }

    .form-control {
        overflow: auto;
        max-height: 200px;
        margin-bottom: calc($margin / 2);
        resize: none;

        &::placeholder {
            opacity: 0.5;
        }

        &::placeholder {
            opacity: 0.5;
        }
    }

    .form-group {
        margin: 0;
    }
}
