@import "../../styles/mixins";

.SearchInput {
    @include flex(center, center);

    width: 100%;

    > input {
        margin: 14px 24px;
    }
}
