@import "../../styles/mixins";
@import "../../styles/vars";

.FlowItem {
    &.list-group-item {
        @include flex(space-between, center, $wrap: nowrap);

        &-warning {
            background-color: rgba($warning-yellow, 0.6);
            color: #715c08;
        }

        &-info {
            background-color: rgba($warning-orange, 0.4);
            color: #814d2f;
        }

        > .flow-item-description {
            flex: 1;
        }

        > .flow-item-verification-info {
            @include flex(center, center);

            flex-shrink: 0;
            width: 24px;
            height: 24px;
            margin: 4px 0 4px 2px;
            border-radius: 50%;
            background-color: rgba(#fff, 0.8);
            font-weight: 700;
            font-size: 14px;
            line-height: 1;

            > svg {
                font-size: 13px;
            }
        }
    }
}

.flow-item-with-checkbox {
    display: grid;
    grid-template-columns: 1fr 4fr;

    > * {
        flex-grow: 1;
    }

    .input-group {
        margin-bottom: 0;
    }

    .input-group-text {
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        border-radius: 0;
    }

    .flow-link {
        text-decoration: none;
    }
}
