@mixin flex($spacing: start, $alignment: start, $direction: row, $wrap: wrap) {
    display: flex;
    flex-direction: $direction;
    flex-wrap: $wrap;
    justify-content: $spacing;
    align-items: $alignment;
}
@mixin overflowX() {
    position: relative;
    overflow-x: auto;
    -ms-overflow-style: none; // hide scrollbar IE
    scrollbar-width: none; // hide scrollbar Mozilla

    &::-webkit-scrollbar {
        display: none; // hide scrollbar
    }
}
@mixin theme-colors-background() {
    &--badoo {
        background-color: #7000e3;
    }

    &--hon {
        background-color: #0070e2;
    }

    &--chat_and_date {
        background-color: #fb6770;
    }

    &--fiesta {
        background-color: #1db6cb;
    }

    &--bumble {
        background-color: #f9c737;
    }

    &--lumen {
        background-color: #ffd400;
    }

    &--fruitz {
        background-color: #fc537f;
    }

    &--maya {
        background-color: #f9c737;
    }
}
