// BOOTSTRAP variables update

// https://getbootstrap.com/docs/5.0/customize/color/ themes for bootstrap classes
$primary: #000;
$dark: #000;
$warning: #fd7e14;

$theme-colors: (
    "primary": $primary,
    "dark": $dark,
    "warning": $warning,
);

@import "~bootstrap/scss/bootstrap.scss"; // we need this so we can update bootstrap variables

// CUSTOM variables update
body {
    &.theme-badoo {
        --theme-color: #7000e3;
    }

    &.theme-hon {
        --theme-color: #0070e2;
    }

    &.theme-chat_and_date {
        --theme-color: #fb6770;
    }

    &.theme-fiesta {
        --theme-color: #1db6cb;
    }

    &.theme-bumble {
        --theme-color: #f9c737;
    }

    &.theme-lumen {
        --theme-color: #ffd400;
    }

    &.theme-fruitz {
        --theme-color: #fc537f;
    }

    &.maya {
        --theme-color: #f9c737;
    }
}
