.ScreenshotWithPane {
    position: relative;

    &.expanded {
        float: none;
        clear: both;
        margin-left: 21.2%;

        .ScreenshotContainer {
            transform: translateX(-70%);
            transition: transform 150ms ease-in, opacity 150ms ease-in;
        }
    }

    .ScreenshotContainer {
        position: relative;
        z-index: 2;
    }

    .ScreenshotStatPane {
        position: absolute;
        top: 0;
        bottom: 30px;
        left: 100%;
        overflow: hidden;
        width: 100%;
        padding: 10px 20px;
        border-top: 1px solid var(--theme-color);
        opacity: 0;
        transform: translateX(-100%) scale(0.8);
        transition: none;

        &::after {
            position: absolute;
            top: 50%;
            right: 4px;
            width: 10px;
            height: 10px;
            margin-top: -5px;
            content: "\25B6\FE0E";
        }

        &.expanded {
            width: 150%;
            opacity: 1;
            transform: translateX(-45%) scale(1);
            transition: all 150ms ease-in;

            &::after {
                display: none;
            }

            &:hover {
                transform: translateX(-45%) scale(1);
            }
        }

        h1,
        h2,
        h3,
        h5 {
            margin-top: 0;
            font-weight: normal;
        }

        h3 {
            color: #000;
            font-weight: 500;
            font-size: 17px;
        }

        h5 {
            color: #888;
            text-align: center;
        }

        hr {
            margin: 15px 0;
        }

        table {
            width: 100%;

            &.ScreenInfo {
                width: auto;
                margin-bottom: 9px;
                font-size: 17px;

                h3 {
                    margin: 0;
                }

                td {
                    padding: 5px 10px 5px 0;
                    border: 0;
                }
            }

            th {
                overflow: hidden;
                padding: 5px 10px 5px 0;
                border-bottom: 2px solid #e6e6e6;
                white-space: nowrap;
            }

            tr:last-child td {
                border: 0;
            }

            td,
            th {
                position: relative;
                max-width: 200px;
                padding: 5px;
                border-bottom: 1px solid #e6e6e6;

                &.numeric {
                    text-align: right;
                }
            }
        }

        table + h1 {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 2px solid #eee;
        }

        strong {
            color: #4e4e4e;
        }

        .no-results {
            position: absolute;
            top: 50%;
            left: 0;
            vertical-align: middle;
            width: 100%;
            margin-top: -0.5em;
            text-align: center;
        }

        .stats {
            position: absolute;
            z-index: -1;
            top: 0;
            bottom: 0;
            left: 0;
            border-radius: 0 5px 5px 0;
        }
    }
}

.MetricTooltipText {
    display: inline-block;
    vertical-align: super;
    width: 12px;
    height: 12px;
    margin-right: 4px;
    margin-left: 4px;
    padding: 5px 2px;
    border: 1px solid black;
    border-radius: 100%;
    background: white;
    text-align: center;
    font-size: 8px;
    line-height: 0px;
    cursor: help;
}

.MetricTooltip {
    opacity: 1;
    pointer-events: none;

    .rc-tooltip-inner {
        background: #fafafa;
        color: #222;
    }

    .rc-tooltip-arrow {
        border-top-color: #eee;
    }
}
