/* sizes */
$padding--screenshot-footer: 14px;
$height--comparison-indicator: 17px;
$height--screenshot-footer: $padding--screenshot-footer + $height--comparison-indicator;
$padding--card: 0.75rem 1rem;

/* columns */
$card-columns-count: 4;

/* shadows */
$input-focus-box-shadow: none;
$btn-active-box-shadow: none;

/* colours */

$input-focus-border-color: var(--theme-color);
$dropdown-link-active-bg: var(--theme-color);

$active-background: var(--main-color);

$link-color: var(--main-color);
$link-hover-color: var(--main-color);

$navbar-dark-color: #fff;
$success-green: #28a745;
$error-red: #dc3545;
$info-blue: #17a2b8;
$warning-orange: #fd7e14;
$warning-yellow: #ffc107;
