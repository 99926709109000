.u-bg-red {
    background-color: #ff6961;
}

.u-bg-green {
    background-color: #7d7;
}

.u-bg-grey {
    background-color: rgba(0, 0, 0, 0.1);
}
