@import "../../styles/vars";

.PanelToggle {
    position: relative;
    padding: 0 50px 0 0;

    .csms-toggle {
        position: absolute;
        top: 50%;
        right: 0;
        margin-top: -12px;

        > input[type="checkbox"],
        input[type="radio"] {
            cursor: pointer;
        }
    }

    &.exclude-identical-toggle {
        margin-top: 25px;
    }

    &.different-only-toggle {
        position: relative;
        margin-top: 25px;

        .different-only-info {
            position: absolute;
            top: 0;
            right: -15px;
            color: $info-blue;
            font-size: 0.8em;
            cursor: pointer;
        }

        &.hidden {
            position: absolute;
            visibility: hidden;
        }
    }
}
