.btn {
    &.btn-primary {
        &.flow-selector-button {
            &--create-task {
                z-index: 3;
                background: var(--main-color);
            }
        }
    }
}
