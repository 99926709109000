//
// Toggle from cosmos
//
// @maintainer: Konstantin Dzuin (k.dzuin@corp.badoo.com)
//

$token-input-choice-size: 24px;
$token-input-toggle-width: 40px;
$token-input-toggle-height: 24px;

.csms-toggle {
    position: relative;
    display: inline-block;
    vertical-align: top;

    > input {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        outline: none;
        border: none;
        background-color: transparent;
        color: transparent;
        opacity: 0;
        appearance: none;
        cursor: default;
    }
}

.csms-toggle--disabled {
    opacity: 0.5;
}

.csms-toggle__surrogate {
    $control-width: $token-input-toggle-width;
    $control-height: $token-input-toggle-height;
    $control-knob-inset: 1px;
    $control-knob-size: ($control-height - 2 * $control-knob-inset);

    position: relative;
    display: block;
    width: $control-width;
    height: $control-height;
    border-radius: 12px;
    background: #c4c4c4;
    transition: background 0.2s;

    &::before {
        position: absolute;
        top: $control-knob-inset;
        left: $control-knob-inset;
        width: $control-knob-size;
        height: $control-knob-size;
        content: "";
        border: 1px solid rgb(0 0 0 / 10%);
        border-radius: 50%;
        background: #fff;
        background-clip: content-box;
        box-shadow:
            0 6px 2px 0 rgb(0 0 0 / 5%),
            0 4px 4px 0 rgb(0 0 0 / 10%),
            0 6px 6px 0 rgb(0 0 0 / 5%);
        transform: translate3d(0, 0, 0);
        transition: transform 0.2s;
    }

    // checked status

    input:checked + & {
        background: #88d03f;

        &::before {
            transform:
                translate3d(
                    ($control-width - $control-knob-size - 2 * $control-knob-inset),
                    0,
                    0
                );
        }
    }
}
