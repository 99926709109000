.modal-content {
    position: relative;

    .modal-body {
        overflow: auto;
        max-height: 70vh;
        padding-bottom: 5em;
    }

    .modal-footer {
        position: absolute;
        z-index: 10;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: #fff;
    }
}
