@import "../../styles/mixins";

$arrow-icon-margin: 16px;

.NavigationButton {
    @include flex();

    margin-bottom: 10px;

    &.left {
        justify-content: end;

        svg {
            margin-right: $arrow-icon-margin;
        }
    }

    &.right {
        svg {
            margin-left: $arrow-icon-margin;
        }
    }
}
