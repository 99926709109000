.LoginPanel {
    &.card {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 420px;
        text-align: center;
        transform: translate(-50%, -50%);
    }
}

.LoginButton {
    position: relative;
    width: 100%;
    height: 38px;
    margin-top: 20px;
    margin-bottom: 20px;
}
