@import "../../styles/mixins";

.FlowComparisonPage {
    @keyframes rotateAroundCircle {
        0% {
            transform: translate(-50%, -50%) rotate(0deg) translate(50%) rotate(0deg);
        }

        100% {
            transform: translate(-50%, -50%) rotate(360deg) translate(50%) rotate(-360deg);
        }
    }

    .Expand {
        width: 100%;
        margin-bottom: 20px;
        background: var(--main-color);
        color: #fff;
        font-weight: bold;

        &:active,
        &:focus {
            background: var(--main-color);
            color: #fff;
        }
    }

    .ScreenshotList .SuggestionButton {
        width: 100%;
        margin-bottom: 10px;
        color: white;
        font-weight: bold;

        &:active,
        &:focus {
            background: var(--main-color);
            color: #fff;
        }
    }

    .all-flows-identical {
        margin-top: 50px;
        text-align: center;
        font-size: 25px;
    }

    .loading-exclude-identical {
        margin: auto;
        fill: var(--theme-color) !important;
    }

    .FlowComparisonPageIdenticalFiltersMessage {
        .card-body {
            text-align: center;

            .filter-values {
                @include flex(center, center);

                margin: 0.6em 0 0 0;
                list-style: none;
                font-weight: 600;

                > li {
                    margin: 0 0.8em;

                    &:not(:first-of-type) {
                        position: relative;

                        &::before {
                            position: absolute;
                            top: 0;
                            left: -1em;
                            content: "\2022";
                        }
                    }
                }
            }
        }
    }

    .flow-comment-comparison-hint {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translateX(-50%);
        background-color: #343a40;
        color: #fff;
        border: 1px solid #ddd;
        padding: 8px;
        z-index: 10;
        white-space: normal;
        cursor: text;
        border-radius: 4px;
        box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    }


    .flow-comment-comparison {
        position: absolute;
        top: -10px;
        right: -10px;
    }
}

.loader-bee-text {
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    text-align: center;
    line-height: 2;

    > .col {
        flex: 0 0 40%;
        padding: 20px;
        border-radius: 5px;
        background-color: #000;
        color: #fff;
    }

    strong {
        font-size: 30px;
    }
}

.loader-bee-img {
    position: absolute;
    top: 180%;
    left: 50%;
    width: 100px;
    width: 200px; /* Adjust the size of the image as needed */
    height: 100px;
    height: 200px; /* Adjust the size of the image as needed */
    transform: translate(-50%, -50%);
    animation: rotateAroundCircle 2s linear infinite;
}
