.ErrorMessage {
    position: relative;
    padding: 10px 10px 10px 50px;
    border-radius: 5px;
    background: white;

    .ErrorMessageHeading {
        margin-bottom: 10px;
        font-size: 20px;
    }

    .ErrorMessageText {
        color: #b70000;
        white-space: pre-wrap;
        font-family: Consolas, monospace;
    }

    .ErrorMessageTrace {
        overflow: scroll;
        max-height: 200px;
        white-space: pre;
        cursor: pointer;
    }

    &::before {
        position: absolute;
        top: 50%;
        left: 10px;
        width: 21px;
        height: 20px;
        margin-top: -10px;
        content: "";
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#a20000"><path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.351 6.493c-.08-.801.55-1.493 1.351-1.493s1.431.692 1.351 1.493l-.801 8.01c-.029.282-.266.497-.55.497s-.521-.215-.55-.498l-.801-8.009zm1.351 12.757c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z"/></svg>');
        background-position: 1px 0;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        opacity: 0.8;
    }
}
