@import "../../styles/mixins";
@import "../../styles/vars";

.SectionsList {
    .input-group-text {
        border: 0;
    }

    .row .list-group-item {
        margin-left: -4px;
    }

    .card-header,
    .list-group-item {
        cursor: pointer;

        &:hover {
            background: #eee;
        }
    }

    .flows-screenshots-counter {
        @include flex(end, center);

        > .counter {
            display: inline-grid;
            grid-template-columns: repeat(4, auto);
            margin-bottom: 10px;
            padding: 5px;
            border: 1px solid rgba(0, 0, 0, 0.125);
            border-radius: 5px;
            color: rgba(0, 0, 0, 0.8);
            font-size: 14px;

            > span,
            strong {
                width: 100%;
                padding: 2px 5px;
                background-color: #fff;
            }

            > span {
                border-right: 1px solid rgba(0, 0, 0, 0.125);
                text-align: right;

                &:last-of-type {
                    border-right: none;
                }
            }
        }
    }

    .translator-reassign {
        margin: 30px 0 60px 0;
        padding: 2em 2em 3em 2em;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 5px;
        background-color: rgba(0, 0, 0, 0.03);

        > h2 {
            margin-bottom: 1em;
            color: $info-blue;
            font-size: 1.1em;
        }

        &-dropdown {
            position: relative;
            display: grid;
            grid-template-columns: 2fr 1fr;
            column-gap: 10px;

            .DropdownWithFilter {
                &.dropdown {
                    background-color: #fff;

                    .dropdown-menu {
                        width: 100%;

                        > .dropdown-item {
                            padding: 0.7em;
                        }
                    }

                    > button {
                        height: 100%;
                        font-size: 0.9em;
                    }
                }
            }

            .absence-of-updated-version {
                position: absolute;
                right: 0;
                bottom: -4.5em;
                color: $warning-orange;
                font-size: 12px;
            }
        }
    }
}

.modal--reassign {
    .modal-title {
        @include flex(center, center);

        width: 100%;
    }

    .modal-body {
        padding-bottom: 1rem;
        text-align: center;

        .SectionsListTaskReassignModal {
            table-layout: fixed;

            th {
                color: #fff;

                &:first-of-type {
                    background-color: $info-blue;
                }

                &:nth-of-type(2) {
                    background-color: $success-green;
                }
            }
        }

        .info-text-updated-version {
            color: $success-green;
        }

        .accordion {
            .card-header {
                .btn {
                    color: $warning-orange;
                }
            }

            .missing-flows {
                text-align: left;
                font-size: 13px;

                th {
                    background-color: $warning-orange;
                    color: #fff;
                }
            }
        }
    }

    .modal-footer {
        position: static;
        text-align: center;

        > button {
            flex: 1;
        }
    }
}
