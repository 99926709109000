@import "../../styles/mixins";

.BreadcrumbDropdown {
    .dropdown-item {
        @include overflowX();

        > mark {
            padding: 3px 1px;
            border-radius: 5px;
            background-color: transparent;
            color: var(--theme-color);
        }

        &.active {
            > mark {
                background-color: #666;
                color: #fff;
            }
        }

        &:hover {
            background-color: #ccc;
        }

        &:active {
            background-color: var(--theme-color);
        }
    }
}
