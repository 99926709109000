.DropdownWithFilter {
    &.dropdown {
        .dropdown-toggle {
            width: 100%;
            text-align: left;
            font-size: calc(0.3em + 0.7vw);
        }

        .dropdown-menu {
            max-height: 20.4em;
            font-size: calc(0.8em + 0.2vw);

            .FilterDropdown {
                width: calc(100% - 9px);
                margin: 0 4px 4px;
            }
        }
    }
}

@media (width <= 768px) {
    .DropdownWithFilter {
        &.dropdown {
            .dropdown-toggle {
                font-size: 14px;
            }
        }
    }
}
@media (width >= 768px) {
    .DropdownWithFilter {
        &.dropdown {
            .dropdown-toggle {
                font-size: min(calc(0.3em + 0.6vw), 15px);
            }
        }
    }
}
