@import "../../styles/mixins";

.FlowExplorerDropdown {
    .dropdown-item {
        @include overflowX();

        > mark {
            padding: 0;
            background-color: transparent;
            color: var(--theme-color);
        }
    }
}
