@import "../../styles/mixins";

.modal--choose-flows {
    ul {
        &.selected-filters {
            @include flex(start, center);

            padding-left: 0;
            list-style: none;
            color: #000;

            > li {
                margin-right: 0.5em;
                padding: 0.5em 1.5em;
                border-radius: 1.5em;
                background-color: var(--theme-color);
                box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                color: #fff;
                opacity: 0.9;
                text-transform: capitalize;

                &.locale {
                    opacity: 1;
                    font-weight: 700;
                    font-size: 1.2em;
                }
            }
        }
    }

    .flows-response {
        margin-bottom: 2em;
    }
}
