@import "../../styles/_mixins.scss";

.StickyFlow {
    position: sticky !important;
    z-index: 3;
    top: 20px;
    align-self: flex-start;

    .different-only-warning {
        display: block;
        margin-bottom: 20px;
        padding: 5px;
        color: #fff;
        white-space: normal;
        line-height: 2;
    }

    .FlowSelector {
        .dropdown {
            margin-bottom: 5px;
        }

        .card-tcbuildid-message {
            @include flex(space-between, center);

            margin: 8px 16px;
            padding: 6px;
            text-align: center;
            font-size: 12px;

            > .tcbuildid-text {
                font-size: 10px;
            }
        }

        &.different-only-disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

@media (width <= 768px) {
    .StickyFlow {
        max-width: 300px;
    }
}
