@import "../../styles/mixins";

.SearchBar {
    height: 100%;
    margin-left: 12px;

    .rbt {
        min-width: 25em;
        height: 100%;

        .form-control {
            height: 100%;
        }

        a,
        button,
        .dropdown button,
        .dropdown-menu > li > a {
            color: #0f0f0f !important;

            &.active {
                color: #fff !important;
            }
        }

        .dropdown-menu {
            > .dropdown-item {
                padding: 0.5rem 0.7rem;

                > span {
                    display: grid;
                    grid-template-columns: 80% auto;
                    column-gap: 10px;

                    > .highlighter {
                        overflow-x: auto;
                        margin-top: auto;
                        margin-bottom: auto;
                        white-space: pre-line;
                        line-height: 1.5;
                        -ms-overflow-style: none; // hide scrollbar IE
                        scrollbar-width: none; // hide scrollbar Mozilla

                        &::-webkit-scrollbar {
                            display: none; // hide scrollbar
                        }
                    }

                    > .type {
                        .badge {
                            width: 100%;
                        }
                    }
                }
            }
        }

        .notAvailableInLiveshots {
            filter: opacity(65%);
        }

        .type {
            float: right;
        }

        mark {
            padding: 0;
            background-color: transparent;
            color: var(--theme-color);
        }

        .active {
            mark {
                color: #fff;
            }
        }
    }
}
