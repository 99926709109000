@import "../../styles/mixins";
@import "../../styles/vars";

$padding-breadcrumb: 2px;

$flow-status-margin-left: 12px;

.BreadcrumbsNavigation {
    position: relative;
    margin-bottom: 20px;

    .loader-container {
        width: 100px;
        margin-left: $flow-status-margin-left;

        > div,
        svg {
            width: 50px !important; // because inline style applied to div in ReactLoading bootstrap component
            height: 30px !important; // because inline style applied to div in ReactLoading bootstrap component
        }
    }

    a {
        outline: none;
        color: var(--main-color);
    }

    .col {
        @include flex(center, center, $wrap: nowrap);

        nav {
            width: 100%;

            .breadcrumb {
                flex-wrap: nowrap;
                justify-content: start;
                margin: 0;

                .breadcrumb-item {
                    @include flex($wrap: nowrap);

                    white-space: nowrap;

                    button {
                        padding: 0 6px;
                        text-align: left;
                    }

                    &:last-child,
                    &:last-child .dropdown-toggle {
                        white-space: normal;
                    }
                }
            }
        }

        .breadcrumbs-nav-buttons {
            @include flex(center, center, $wrap: nowrap);

            height: 100%;
            margin-left: 12px;

            > button {
                height: 100%;

                &:first-of-type {
                    margin-right: 6px;
                }

                &.btn-outline-secondary {
                    border-color: rgba(0, 0, 0, 0.2);
                }
            }
        }

        .breadcrumbs-flow-status {
            @include flex(center, center);

            width: 100px;
            height: 100%;
            margin-left: $flow-status-margin-left;
            border-radius: 5px;
            background-color: #e9ecef;
            color: $info-blue;

            &.approved {
                color: $success-green;
            }

            &.failed {
                color: $error-red;
            }

            &.reassigned {
                color: $warning-orange;
            }

            &.in_progress,
            &.reassigned_in_progress {
                color: $warning-yellow;
            }

            > svg {
                width: 50px;
                height: 30px;
            }
        }
    }

    .dropdown button,
    .dropdown-menu > li > a {
        outline: none;
        color: var(--main-color);
        user-select: text;

        &:focus,
        &:visited {
            color: var(--main-color);
        }
    }

    .dropdown {
        button {
            &::after {
                margin-left: 10px;
            }
        }
    }

    #verify-dropdown {
        margin-left: 15px;
        padding: 0 4px;
        border: none;
        background-color: transparent;
        text-transform: capitalize;
    }

    &.comparison {
        .dropdown-toggle,
        .breadcrumb-item {
            white-space: normal;
            font-size: 13px;
        }

        > .col {
            flex-direction: column;

            .card {
                width: 100%;
                margin-top: 12px;
            }
        }
    }
}
