.BreadcrumbDropdown {
    .dropdown {
        .dropdown-menu {
            min-width: 23vw;
            width: 100%;
            max-height: 46vh;
        }

        button,
        button:focus {
            padding: 0 4px;
            border: 0;
            background: none;
            box-shadow: none;
        }
    }

    .single-item {
        color: #6c757d;
    }
}
