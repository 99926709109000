@import "../../styles/mixins";

.FlowExplorerDropdown {
    min-height: 40vh;

    > .dropdown {
        .dropdown-toggle {
            min-width: 15em;
            margin: 0 auto;

            > span,
            &::after {
                margin-left: auto;
            }
        }

        .dropdown-menu {
            width: 35vw;
            max-height: 46vh;
        }
    }
}
