//
// SPACER (utility component)
//

$token-spacing-xsm: 4px;
$token-spacing-sm: 8px;
$token-spacing-md: 12px;
$token-spacing-lg: 16px;
$token-spacing-xlg: 24px;
$token-spacing-xxlg: 32px;
$token-spacing-gap: 16px;

$tokens-spacing-map: (
    size: (
        xsm: $token-spacing-xsm,
        sm: $token-spacing-sm,
        md: $token-spacing-md,
        lg: $token-spacing-lg,
        xlg: $token-spacing-xlg,
        xxlg: $token-spacing-xxlg,
        gap: $token-spacing-gap,
    ),
);

@each $size, $token in map-get($tokens-spacing-map, "size") {
    .csms-spacer--top-#{$size} {
        padding-top: $token;
    }
    .csms-spacer--right-#{$size} {
        padding-right: $token;
    }
    .csms-spacer--bottom-#{$size} {
        padding-bottom: $token;
    }
    .csms-spacer--left-#{$size} {
        padding-left: $token;
    }
}
