@import "../../styles/mixins";

.SearchResultPage {
    h2 {
        font-weight: normal;
    }

    h2 + p {
        margin-bottom: 30px;
    }

    .Screenshot {
        &:hover {
            .ScreenshotBottomOverlay {
                border-radius: 5px;
            }
        }
    }

    .ScreenshotCaption {
        justify-content: left;
        overflow-x: auto;
    }
}
