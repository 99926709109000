.TripleRow {
    &.triple-row-flow-description {
        margin-bottom: 24px;

        .flow-description {
            height: 100%;

            .card-body {
                padding: 0.75em 1.25em;
            }
        }
    }

    &.triple-row-breadcrumb {
        margin-bottom: 12px;

        .BreadcrumbsNavigation {
            height: 100%;
            margin-bottom: 0;

            nav,
            .breadcrumb {
                height: 100%;
            }
        }
    }
}
