@import "../../styles/mixins";
@import "../../styles/vars";

.FlowExplorerRow {
    @include flex(center, center, column);

    position: relative;

    .ScreenshotWithPane {
        margin: 0 35%;
    }

    .fa-arrow-alt-circle-down {
        margin-bottom: 20px;
        color: var(--theme-color);
    }

    .FlowComplete {
        margin-bottom: 20px;
        color: $success-green;
    }
}
