@import "../../styles/mixins";

.AdminPage {
    @include flex(center, center, column, nowrap);

    .row {
        justify-content: end;
        width: 100%;

        &.admin-table-row {
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: start;
            align-items: center;
            overflow: auto;
            height: 80vh;

            .col-md-9,
            .col-12 {
                max-width: unset;
                width: 100%;
                padding: 0;
            }
        }
    }

    .btn {
        &.save-changes {
            width: 150px;
            margin: 20px 0;
            color: #fff;
            font-weight: bold;

            &[disabled] {
                background: var(--main-color);
                transform: translateY(0);
            }
        }
    }
}
