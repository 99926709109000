@import "../../styles/mixins";

.TasksTable {
    th,
    td {
        text-align: center;
    }

    .tr-body {
        cursor: pointer;

        &:hover {
            background-color: #eee;
        }
    }

    .th-icon,
    .td-icon {
        text-align: center;
    }

    .td-icon {
        > * {
            width: 100%;
            padding: 0.375rem 0;
            line-height: 1.5;

            > svg {
                width: 0.8em;
            }
        }

        > button,
        > a {
            width: 80%;
        }

        /* react-bootstrap Badge component class */
        .badge-warning {
            background-color: #ffc107;
        }

        /* react-bootstrap Button component class */
        .btn {
            overflow: hidden;
        }
    }

    .task-product {
        @include flex(center, center);
        @include theme-colors-background();

        color: #fff;
        text-align: center;
    }
}
