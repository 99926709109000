@import "./mixins";
@import "./vars";

body {
    --main-color: #000;
    --theme-color: #000;

    overflow-x: hidden;
    background: #fcfcfc;
}

// Support for wider screens
@media (min-width: 1400px) {
    .container {
        width: 1370px;
    }
}
@media (min-width: 1400px) {
    .App {
        .container,
        .container-lg,
        .container-md,
        .container-sm,
        .container-xl {
            max-width: 1370px;
        }
    }
}

h3 {
    color: #888;
    font-size: 16px;
}

.ReactModal__Body--open {
    overflow: hidden;
}

.ReactModal__Overlay {
    z-index: 100;
}

.navbar-brand {
    padding-left: 15px;

    .nav-link {
        padding: 0;

        img,
        span {
            width: 110px;
            height: 40px;
            color: #fff;
            // margin-bottom: -4px;
        }
    }
}

.navbar-nav.justify-content-end a {
    margin-right: 9px;
    border-radius: 5px;
    background: rgba(255, 255, 255, 0.2) !important;
    text-decoration: none;
}

.card {
    box-shadow: 0 5px 5px -5px #a2a2a2;

    .card-body,
    .card-header {
        padding: $padding--card;
    }
}

.ScreenshotList {
    $margin-bottom: 40px;

    justify-content: center;

    .ScreenshotWithPane {
        margin-bottom: $margin-bottom;
    }

    .ScreenshotWithoutPane {
        margin-bottom: $margin-bottom;

        &.loading {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: calc(34px + #{$margin-bottom}); // 34px footer height
            padding: 0;
            border-radius: 5px;
            background-color: rgba(0, 0, 0, 0.1);

            > div {
                position: absolute;
                top: 50%;
                left: 50%;
                padding: 10px;
                transform: translate3d(-50%, -50%, 0);
            }
        }

        .Screenshot {
            margin-right: -15px;
            margin-left: -15px;
        }
    }
}

.loader-container {
    @include flex(center, center);
}

.AbsoluteCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
}

.SuggestionButton {
    width: 100%;
    margin-bottom: 10px;
    color: white !important;
    font-weight: bold;

    &:active,
    &:focus {
        background: var(--main-color);
        color: #fff;
    }
}

.flow-actions {
    margin-top: 15px;
    margin-bottom: 20px;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
    margin-bottom: 15px;
}

.card-columns .card {
    margin-bottom: 15px;
}

.navbar {
    border-bottom: 5px solid var(--theme-color);
}

.input-group .list-group-item {
    flex-grow: 1;
}

.select-all .input-group-text {
    border: white;
    background-color: white;
}

.non-clickable {
    cursor: default;
    pointer-events: none;
}

.text-link {
    text-decoration: underline;

    &:visited {
        color: #551a8b;
    }
}

.not-found-suggestion {
    padding: 0 1.25em 1.25em 1.25em;
    text-align: center;
    font-weight: 400;
    line-height: 1.4;

    > span {
        display: inline-block;
        margin-bottom: 16px;
    }
}

table {
    &.task-table {
        width: 100%;

        tr {
            border-bottom: 1px dotted rgba(0, 0, 0, 0.1);

            &.tr-header {
                border-bottom: 1px solid rgba(0, 0, 0, 0.125);
            }

            &:last-child {
                border-bottom: 0;
            }
        }

        td,
        th {
            padding: 13px;
        }

        th {
            padding-bottom: 18px;
        }
    }
}
